<template>
  <div class="home">
    <!-- 头部 -->
    <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

    <!-- tab -->
    <div class="brandDead">
      <div class="son">
        <div class="title">分类</div>
        <div style="display: flex;flex-wrap: wrap;row-gap: 10px;column-gap: 20px;">
          <div class="tabs cur" :class="[qbFLShow ? 'tabsAct' : '']" @click="qbFL">全部</div>
          <div class="tabs cur" style="display: flex;flex-wrap: wrap;" v-for="(i, index) in goodsArrNav" :key="index">
            <div class="tab" :class="[i.isShow ? 'tabsAct' : '']" @click="tabsClick(index, i)">{{ i.typeName }}
            </div>
          </div>
        </div>
      </div>
      <div class="son">
        <div class="title">品牌</div>
        <div class="tabImg" v-for="(i, index) in brandListPage.slice(0, 7)" :key="index" @click="searchClick(i)">
          <div class="img">
            <div>
              <img :src="i.logo" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 主体内容 -->
    <div class="exclusiveContent" v-loading="loading">
      <div class="son" v-for="(item, index) in brandList" :key="index">
        <div class="img">
          <img :src="item.logo" alt="">
          <div class="btn cur" @click="searchClick(item)">{{ item.brandName }}</div>
        </div>
        <div class="r">
          <div class="sun" v-for="(i, index) in item.children" :key="index" @click.stop="detailsClick(i)">
            <div class="img" style="width: 120px;height: 120px;overflow: hidden;">
              <img style="object-fit: contain;width: 100%;" :src="i.mainImage" alt="">
            </div>
            <div class="name" style="margin-top: 5px;"> {{ i.title }}</div>
          </div>
          <div v-if="!item.children.length"
            style="width: 100%;text-align: center;margin: 0px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
            <img src="@/assets/wsj.png" style="width: 100px;object-fit: contain;" alt="">
            <div style="width: 100%;margin-top: 10px;">暂无推荐</div>
          </div>
          <div class="gend" @click="searchClick(item)">
            <span> 更多专享</span>
            <img src="@/assets/icon/gd.png" alt="">
          </div>
        </div>

      </div>

      <!-- 分页 -->
      <paging :total="shoppingListTotal" @handleCurrentChange="handleCurrentChange" :page-size="paging.pageSize"></paging>

    </div>


    <!-- 底部 -->
    <!-- <bottomContent></bottomContent> -->
  </div>
</template>

<script>
import { getclassificationOfBrand } from "@/utils/api/headApi/index"
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import { getBrandExclusive, getGoodsSearchBrand } from "@/utils/api/homeApi/index"
import paging from "@/components/paging.vue";
export default {
  name: 'brandExclusive',
  components: {
    largeHead,
    bottomContent,
    paging
  },
  data() {
    return {
      loading: true,
      // 头部激活菜单
      tabArrShow: 6,
      // 一级分类
      goodsArrNav: [],
      // 品牌列表
      brandList: [],
      // 分页
      shoppingListTotal: 0,
      // 分页
      paging: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      // 分页1的数据
      brandListPage: [],
      // 分类id
      typeId: '',
      qbFLShow: true
    };
  },
  watch: {
    '$store.state.goodsArrNav'(newVal, oldVal) {
      this.goodsArrNav = this.$store.state.goodsArrNav
      this.goodsArrNav.map((t) => (t.isShow = false));
    },
  },
  mounted() {
    // this.getExclusive()
    this.getBrand()
    this.goodsArrNav = this.$store.state.goodsArrNav
    this.goodsArrNav.map((t) => (t.isShow = false));
  },
  methods: {
    // 获取品牌专享
    async getExclusive() {
      const res = await getBrandExclusive(this.paging)
      if (res.statusCode == 8201) {
        this.brandExclusive = res.data
      }
    },
    tabsClick(index, i) {
      this.paging.currentPage = 1
      this.loading = true
      this.goodsArrNav.map((t) => (t.isShow = false));
      this.qbFLShow = false
      this.goodsArrNav[index].isShow = true;
      console.log(i.id)
      this.typeId = i.id
      this.getBrand(i.id)
    },
    qbFL() {
      this.paging.currentPage = 1
      this.loading = true
      this.goodsArrNav.map((t) => (t.isShow = false));
      this.qbFLShow = true
      this.typeId = ''
      this.getBrand(this.typeId)
    },
    // 获取品牌列表
    async getBrand(id) {
      const res = await getclassificationOfBrand({ size: this.paging.pageSize, current: this.paging.currentPage, typeId: id || '', isBrand: true })
      if (res.statusCode == 8201) {

        if (this.paging.currentPage == 1) {
          this.brandListPage = res.data.records
        }

        this.brandList = res.data.records
        this.shoppingListTotal = res.data.total
        this.brandList.map(p => {
          this.$set(p, 'children', [])
        })

        this.brandList.map(async d => {
          let data = {
            keyWord: d.brandName,
            sPage: 1,
            size: 5,
            type: 'ALL'
          }
          const res = await getGoodsSearchBrand(data)
          if (res.statusCode == 8201) {
            d.children = res.data.records
            this.loading = false
          }
        })
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.loading = true
      this.$store.commit("scrollToTop")
      console.log(val)
      this.paging.currentPage = val
      this.getBrand(this.typeId)
    },
    // 根据品牌搜索商品
    searchClick(i) {
      window.open(`#/search2?keyWords=${i.brandName}&type=brand`, '_blank');
    },
    // 商品详情页
    detailsClick(i) {
      window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
    },
  }
}
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;

  // 删选样式 tab
  >.brandDead {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;

    >.son {
      width: 100%;
      height: auto;
      font-size: 14px;
      display: flex;
      padding-bottom: 15px;
      border-bottom: 1px solid #F5F5F5;
      margin-top: 12px;
      align-items: center;

      >.title {
        width: 65px;
        color: #666;
        margin-right: 20px;
      }

      >.tabs {
        color: #333;
        display: flex;

        >.tab {
          margin-right: 20px;
          transition: all .3s;
          cursor: pointer;
        }

        >.tab:hover {
          color: #FF4242;
          transition: all .3s;
        }
      }

      >.tabImg {
        height: auto;
        display: flex;

        >.img {
          width: 130px;
          height: 50px;
          padding: 10px 25px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          border: 1px solid #E0E0E0;
          margin-right: 20px;
          transition: all .3s;

          >div {
            width: 80px;
            height: 30px;

            >img {
              width: 100%;
              height: 100%;
            }
          }
        }

        >.img:hover {
          border: 1px solid #FF4242;
          transition: all .3s;
        }
      }
    }
  }

  // 主体列表
  >.exclusiveContent {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    border-radius: 8px;
    background-color: #fff;
    // padding: 20px;
    box-sizing: border-box;

    >.son {
      width: 100%;
      height: 248px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F0F0F0;

      >.img {
        width: 25%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        >img {
          width: 200px;
          height: auto;
        }

        >.btn {
          width: 126px;
          height: 40px;
          border-radius: 20px;
          background-color: #FF4242;
          font-size: 16px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          margin-top: 15px;
          cursor: pointer;
        }
      }

      >.r {
        width: 847px;
        height: 204px;
        background-color: #F0F0F0;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        // justify-content: space-between;
        gap: 20px;

        >.sun {
          width: 140px;
          height: 160px;
          border-radius: 4px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          box-sizing: border-box;
          cursor: pointer;
          transition: all .3s;
          border: 1px solid #fff;

          >.img {
            width: 120px;
            height: 120px;

            >img {
              max-width: 100%;
              /* 图片的最大宽度为容器的宽度 */
              max-height: 100%;
              /* 图片的最大高度为容器的高度 */
              display: block;
            }
          }

          >.name {
            width: 120px;
            color: #333;
            font-size: 12px;
            transition: all .3s;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        >.sun:hover {
          border: 1px solid #FF4242;
          transition: all .3s;

          >.name {
            color: #FF4242;
          }
        }

        >.gend {
          width: 16px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #333;
          cursor: pointer;
          transition: all .3s;

          >span {
            line-height: 15px;
          }

          >img {
            width: 16px;
            height: 16px;
            margin-top: 5px;
          }
        }

        >.gend:hover {
          color: #FF4242;
          transition: all .3s;
        }
      }
    }
  }


  // 分页
  .paging {
    width: 1200px;
    height: 50px;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
  }
}

.tabsAct {
  color: #ff4242;
  font-weight: 700 !important;
  transition: all 0.3s;
}

// 分页样式
/deep/ .el-pagination .active {
  background-color: #ff4242 !important;
  transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
  color: #fff !important;
  transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff;
  border: 1px solid #fff;
  transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff4242;
  border: 1px solid #ff4242;
  transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
  color: #ff4242 !important;
  transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
  color: #ff4242 !important;
  transition: all .3s !important;
}
</style>